<template>
    <v-container ref="mibbox" class="monitorItemBox ma-0 pa-2 width100" style="height:calc(100% - 38px)">        
        <v-card  v-intersect="onIntersect" class="width100 height100" min-height="280"  min-width="420">
            <div class="weather" v-show="timeAndWeather">                
                <div class="mx-1" title="风向" ><v-icon>mdi-windsock</v-icon>{{weatherInfo.windDirection}}</div>
                <div class="mx-1" title="风力"><v-icon>mdi-weather-windy</v-icon>{{weatherInfo.windPower}}</div>            
                <div class="mx-1" title="天气"><v-icon>{{icon(weatherInfo.weather)}}</v-icon>{{weatherInfo.weather}}</div>                              
            </div>
            <div class="timeText" v-show="timeAndWeather">{{timeText}}</div>
            <ColorBarConfig v-show="false" :dataKey="2" ref="colorBar" class="mapColorBarConfig" />           
            <div class="hotBtn">
                <v-switch title="开启天气时间"                    
                    v-model="timeAndWeather"                    
                    class="mt-0"
                    label="天气时间"
                    hide-details
                    dense>
                    </v-switch>
                <v-switch title="开启热力图"
                    @change="setHotLayer($event);if($event){setOpacity(5)}else{setOpacity(7)}"
                    v-model="hotLayer"
                    class="mt-0"
                    label="热力图"
                    v-show="permissionUser.hotLayerShow"
                    hide-details
                    dense>
                    </v-switch>
                <v-switch title="显示报警点"
                    @change="setAlarmPoint"
                    v-model="alarmPoint"
                    v-show="permissionUser.alarmPointShow"
                    class="mt-0"
                    label="报警点"
                    hide-details
                    dense>
                    </v-switch>
            </div>
            <monitorPointDataCard ref="monitorPointDataCard" class="monitorpointCard" />
            <div class="mb-map noevent" ref="scanMap"></div>
            <v-row class="markIcon">
                <v-card class="d-flex">
                    <div class="ma-1" v-for="(i,k) in customMark.types" @click="i.show = !i.show;setCustomMarker(i)" :key="k" >
                        <div v-html="i.icon"></div>
                        <v-icon style="position: absolute;top: 10px;" v-show="!i.show">
                            mdi-cancel
                        </v-icon>                        
                    </div>
                </v-card>                
            </v-row>                 
        </v-card> 
    </v-container>
</template>

<script>
import * as echarts from "echarts"
import "echarts-extension-amap"
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import weatherType from '@/plugins/datas/weatherType.json'
import transferDataType from '@/plugins/datas/transferDataType'
import monitorPointDataCard from '@/components/cardMenu/monitorPointDataCard'
import {getAlarmPointsApi,getGisApi} from '@/api' 
export default {
    name:"Scanmap",
    components: {
        ColorBarConfig,monitorPointDataCard
    },
    props:{
        siteCount:{
            type:Number
        },
        sites:{
            type:Array
        },        
        site:{
            type:Object
        }
    },
    watch:{
        site:{
            handler(n){
                this.currentSite = n;
            }
        },
        "$vuetify.theme.isDark":{
            handler(n,v){
                if(n){
                    if(this.map){
                        this.map.setMapStyle('amap://styles/dark')
                    }
                }else{
                    if(this.map){
                        this.map.setMapStyle('amap://styles/macaron')
                    }
                }
            }
        },
        siteCount(n){
            var target = this.$refs.scanMap   
            this.colorBarHeight =Math.floor(target.clientHeight * 0.6)            
        },
        "$store.state.storage_global.message":{
            handler(n){   
                if(n.siteId == this.currentSite.siteId){   
                    let checkedId = this.currentSite.alarmFilterRule.map(a=>a.id);
                    let _filterRel = JSON.stringify(n.pointFilter);
                    let _rel = false;
                    for(let filterRule in this.checkedId){
                        if(_filterRel.indexOf(filterRule+":false") >= 0){
                            _rel = true
                        }else{
                            _rel = false
                        }
                    }             
                    if(_rel){
                        let obj = {
                            time:n.date,
                            lng:n.lng,
                            lat:n.lat,
                            position:n.position?.formatted_address,
                            province:n.position?.addressComponent.province,
                            city:n.position?.addressComponent.city,
                            street:n.position?.addressComponent.township,
                            district:n.position?.addressComponent.district,
                            count:n.count
                        }                                                       
                        if(this.alarmList[0]?.date == n?.date){                       
                            this.alarmList.push(obj)
                        }else{
                            this.alarmList = []
                            this.alarmList.push(obj)
                        }                         
                    }        
                    
                }
            }
        },
        currentSite(n){                                              
            if(n.extendInfo){            
                let extendInfo = JSON.parse(n.extendInfo)                
                this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
            }else{
                this.scanDistance = 6
            }          
            this.lidarDatas = [];    
            this.watchData(n.siteId)        
            this.loadMap()                 
            if(this.hotLayer){
                this.setHotLayer(this.hotLayer)
            }
            if(this.alarmPoint){
                this.setAlarmPoint(this.alarmPoint)
            }
        },
    },
    data(){
        return {    
            permissionUser:{
                hotLayerShow:true,
                alarmPointShow:true,
            },          
            scanDistance:6,
            timeAndWeather:true,//天气时间
            visit:false, 
            timeText:"",           
            currentSite:{
              siteName:"未选中",
              commitStatus:0,
            },
         
            FIX_ScanTypes:[],//定点数据类型
            RHI_ScanTypes:[],//垂直扫描类型
            vertical_ScanTypes:[],//垂直扫描选项
            onboarding:'relaScanMap',
            map:null,
            mapEchart:null,
            center:[],
            mapContextMenuPositon:[],
            weatherInfo:{
            },
            colorBarHeight:0,

            alarmDatas:[],
            //热力图
            mapHotLayer:null,
            mapHotDatas:[],
            hotLayer:false,            
            color:{
                0.2: '#5e04fb',
                0.5: '#a0ff2f',
                0.7: '#fde468',
                1.0: '#d7191c'
            },

            //报警列表
            alarmPoint:false,
            loadingWarnInfo:false,        
            alarmList:[],            
            width:0,
            height:0,        

            siteMarker:null,
            //画图
            lidarDatas:[],
            canvas:null,
            ctx:null,
            canvasLayer:null,
            opacity:0.7,
            noDateCount:0,
            setOpacity:null,
            monitorPointMarkers:[],    //国控点    
            timmer:null,
            clearChartFun:null,
            hiddenDom:false,
            loadTimer:null,
          
            //垂直按钮
            currentVerticalType:"",//垂直扫描选择

            //时间热图  垂直使用
            hotChart:null,
            moveHeight:320,
            hotChartData:[],
            currentWatch:null,
            customMark:{}
        }
    },
    computed:{
        icon:()=>{
            return(key)=>{
                var item = weatherType.find(a=>a.name == key);
                if(item){
                    return item.value
                }else{
                    return 'mdi-biohazard'
                }
            }
        }      
    },
    beforeDestroy(){
        if(this.currentWatch){
                this.currentWatch()
            }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
            this.map = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    beforeUnmount(){
        if(this.currentWatch){
                this.currentWatch()
            }
        if(this.timmer){
            clearInterval(this.timmer)
        }
        this.mapEchart = echarts.getInstanceByDom(this.$refs.scanMap);
        if(this.mapEchart != null){                    
            this.mapEchart.clear()
            this.mapEchart = null;
            this.map = null;
        }  
        document.removeEventListener("visibilitychange",this.clearChartFun)   
    },
    mounted(){
        this.currentSite = this.site;                                
        var that = this;
        if(this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsWindowUnion?.scanMap){
            this.permissionUser = this.$store.state.storage_user.user?.extendInfo?.permissionUser?.plsWindowUnion?.scanMap
        } 
        if(this.currentSite.extendInfo){            
            let extendInfo = JSON.parse(this.currentSite.extendInfo)                
            this.scanDistance = extendInfo?.scanDistance ? extendInfo?.scanDistance:6;
        }
       setTimeout(() => {
        that.watchData(that.currentSite.siteId)
       }, 200);
       
        var target = that.$refs.scanMap   
        this.colorBarHeight =Math.floor(target.clientHeight * 0.6)
        this.clearChartFun = () => {
            if (document.hidden) {
                that.hiddenDom = true;
                // 清除      
                that.$nextTick(()=>{                
                    that.mapEchart = echarts.getInstanceByDom(target);                    
                    if(that.mapEchart != null){
                        that.mapEchart.clear();                   
                    } 
                })     
                
            } else {
                that.hiddenDom = false;
                // 开启                
                that.$nextTick(()=>{
                    that.mapEchart = echarts.getInstanceByDom(target);
                    if(that.mapEchart != null){
                        that.loadMap()                              
                    }
                })                                 
            }
        };
        document.addEventListener("visibilitychange", this.clearChartFun);     
    },
    methods:{
        watchData(siteId){
            let that = this;
            if(this.currentWatch){
                this.currentWatch()
            }
            this.currentWatch = this.$watch(`$store.state.storage_global.siteData.${siteId}`,(n,v)=>{
                if(n.SiteId == that.currentSite.siteId && n.DataType == 2 && (n.ScanMode == 0 || n.ScanMode == "PPI")){                                               
                    var json = JSON.parse(JSON.stringify(n.jsonData));    
                    if(that.ctx){                                
                        that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)
                    }            
                    if(that.lidarDatas.length > 0 &&(json[0].period != that.lidarDatas[0].period || n.DataType != that.lidarDatas[0].dataType)){
                        that.lidarDatas = [];                                    
                    }                                                                    
                    that.lidarDatas = that.lidarDatas.concat(json)   
                    that.lidarDatas = that.lidarDatas.sort((a,b)=>{
                        return new Date(a.dataTime) - new Date(b.dataTime)
                    })                                                      
                    if(!that.hiddenDom){                                        
                        that.draw()              
                    }                                             
                }
            }, {immediate: true })
        },   
        onIntersect(entries, observer){            
            var target = this.$refs.scanMap             
            this.visit = entries[0].isIntersecting
            if(this.visit){
                this.hiddenDom =false; 
                var that = this;
                // 清除      
                that.$nextTick(()=>{                
                    that.mapEchart = echarts.getInstanceByDom(target);                    
                    if(that.mapEchart != null){
                        that.loadMap()                 
                    } 
                })      
            }else{
                this.hiddenDom = true;
                var that = this;
                // 清除      
                this.$nextTick(()=>{                
                    that.mapEchart = echarts.getInstanceByDom(target);                    
                    if(that.mapEchart != null){
                        that.mapEchart.clear();                   
                    } 
                })  
            }
        },
        loadMap(){            
            let target = this.$refs.scanMap;    
            if(!target){
                return;
            }
            this.colorBarHeight =Math.floor(target.clientHeight * 0.6)       
            var isDark = this.$vuetify.theme.isDark;            
            let theme = isDark ? 'amap://styles/dark':'amap://styles/macaron';
            let zoomB = 12.5;                       
            let mch = target.clientHeight;
            let scale = Number(Math.sqrt(300/mch).toFixed(2)) ;
            let zoom = zoomB - scale;            

            let mapEchart = echarts.getInstanceByDom(target);
            if(mapEchart == null){
                mapEchart = echarts.init(target);
            }  
            mapEchart.clear()
            if(this.canvasLayer){
                this.map.remove(this.canvasLayer)
                this.canvasLayer = null;
            }

            let site = this.currentSite;
            let center = [site.lng,site.lat]
            this.center = center;
            let option = {
                amap: {
                    // 高德地图中心经纬度
                    center:center ,
                    rotateEnable:false,
                    pitchEnable:false,   
                    isHotspot:false,             
                    pitch: 0,
                    rotation: 0,//-55
                    zoom:zoom,//级别
                    zooms:[2,30],
                    viewMode:'3D',//使用3D视图                        
                    // 启用resize
                    resizeEnable: true,
                    // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                    renderOnMoving: true,
                    echartsLayerInteractive: true,
                    largeMode: false,
                    returnMapCameraState: true,
                    // 自定义地图样式主题
                    //53dd45a35fd6d89a2303f51cad6cce14
                    mapStyle: theme
                }
            }
            mapEchart.setOption(option);
            this.mapEchart = mapEchart;                 
            this.map = mapEchart.getModel().getComponent('amap').getAMap();
            //添加右键菜单
            this.addContextMenu();
            //加载控件 
            this.addControl(); 
            var that = this;
            //添加站点位置
            that.siteMarker= new AMap.Marker({
                content: `<svg class='map_marker' style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
            </svg>`,  // 自定义点标记覆盖物内容
                position:center, // 基点位置
                offset: new AMap.Pixel(-8, -20), // 相对于基点的偏移位置                                    
            }); 
            that.siteMarker.on('click',(e)=>{                                
                     var htm = `<div>
                        <div style="margin: 10px 5px;">${site.siteName}</div>
                        <div style="margin: 10px 5px;">位置：${site.localtion}</div> 
                        <div style="margin: 10px 5px;">经纬度：${site.lng},${site.lat}</div>   
                        <div style="margin: 10px 5px;">部署时间：${new Date(site.deploytime.replace("T"," ")).pattern("yyyy年MM月dd日 HH:mm")}</div>  
                        <div style="margin: 10px 5px;float:right"><a id='' href="javascript: void(0)">前往查看</a></div>  
                        </div>`
                    var _el = $help.toDom(htm);              
                    for(var a of _el[0].children){
                        if(a.children && a.children.length > 0){
                            let _link = a.children[0].tagName && (a.children[0].tagName == "a" || a.children[0].tagName == "A")?a.children[0]:null;                                    
                            if(_link){                                                                                
                                _link.onclick=function(){                                            
                                    that.$store.commit('storage_global/updateCurrentSite',site,{root:true}) 
                                    that.$router.push({path:'/monitor/monitorSite/realTimeMonitor'})
                                }
                            }
                        }                                                        
                    }               
                    new AMap.InfoWindow({
                        content: _el[0],                        
                    }).open(that.map,e.lnglat) 
                })
            that.map.add(that.siteMarker)
            that.map.getCity((rel)=>{                
                 //获取天气情况
                getWeather(rel.district,(data)=>{
                    that.weatherInfo = {
                        position:data.city,
                        windPower:data.windPower,
                        temperature:data.temperature,
                        windDirection:data.windDirection,
                        humidity:data.humidity,
                        weather:data.weather
                    }
                }) 
            })
            if(that.hotLayer){
                that.setHotLayer(that.hotLayer)
            }
            if(that.alarmPoint){
                that.setAlarmPoint(that.alarmPoint)
            }
              //加载国控点
            this.loadMonitPoint()                 
        },  
        loadMonitPoint(){
            var that =this;
            this.$store.dispatch("storage_global/getMonitorPoints",(d)=>{
                that.monitorPoints = d;
                if(that.monitorPointMarkers.length > 0){
                    that.map.remove(that.monitorPointMarkers)
                }               
                loadMonitorPointToMap(that.map,that.monitorPoints,(marker)=>{
                    that.monitorPointMarkers.push(marker);
                },(point)=>{
                    that.$refs.monitorPointDataCard.show = true
                    that.$refs.monitorPointDataCard.pointNo = point.BmpPointNo
                    that.$refs.monitorPointDataCard.load()
                })
            },{root:true})      
            loadCustomMarkerToMap(that.map,(markers,types)=>{
                let typesContain = markers.map(a=>a.type)
                that.customMark = {
                    markers:markers,
                    types:types.map(a=>{
                        a.show = true
                        return a
                    }).filter(a=>typesContain.indexOf(a.value) >=0)
                }
            },that.$store.state.storage_user.user.companyKey) 
        },
        setCustomMarker(type){
            let _markers = this.customMark.markers.filter(a=>a.type == type.value)
            _markers.forEach(a=>{
                if(type.show){
                    a.show()
                }else{
                    a.hide()
                }
            })
        },
        addControl(){
            var that = this;
            if(!this.map){
                return;
            }
            AMapUI.loadUI(['control/BasicControl'], function(BasicControl) {
                
                //比例尺
                var scale = new AMap.Scale(
                    {
                        position: [
                            {top: '110px',
                                right: '20px'
                            }
                        ]                  
                    }
                );
                that.map.addControl(scale);
        
                //图层切换控件
                that.map.addControl(new BasicControl.LayerSwitcher({
                    position: {bottom: '30px',left: '20px' },
                    theme:'myself',                
                }));

                //3d指南针控件
                // var controlBar = new AMap.ControlBar({
                //     position:{bottom: '40px',left: '-70px' },
                //     theme:'myself'
                // });
                // that.map.addControl(controlBar) 
                
                //扫描图透明度         
                let el = that.map.getContainer();
                let styleO = 'cursor:pointer;bottom:100px;left:20px;z-index:0;';
                if(that.canvasLayer || that.opacity){                    
                    that.opacity = that.opacity?that.opacity: that.canvasLayer.w.opacity ;
                }else{
                    that.opacity = 0.7
                }                                                                                   
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleO}">
                <div class="amap-ui-control-layer-slider" style="display:flex;">
                    <div class="opacity_box" >
                        <svg style="width:24px;height:24px;margin:5px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M18 10V8H20V10H18M18 12V10H16V12H18M18 8V6H16V8H18M16 2.84V4H18C17.37 3.54 16.71 3.15 16 2.84M18 4V6H20C19.42 5.25 18.75 4.58 18 4M20 6V8H21.16C20.85 7.29 20.46 6.63 20 6M22 12C22 11.32 21.93 10.65 21.8 10H20V12H22M16 6V4H14V6H16M16 16H18V14H16V16M18 18H20L20 18V16H18V18M16 20H18L18 20V18H16V20M14 21.8C14.7 21.66 15.36 21.44 16 21.16V20H14V21.8M18 14H20V12H18V14M16 8H14V10H16V8M20 16H21.16C21.44 15.36 21.66 14.7 21.8 14H20V16M16 12H14V14H16V12M12 18V16H14V14H12V12H14V10H12V8H14V6H12V4H14V2.2C13.35 2.07 12.69 2 12 2C6.5 2 2 6.5 2 12S6.5 22 12 22V20H14V18H12M14 18H16V16H14V18Z" />
                        </svg>
                    </div>                            
                    <div class="_slider">
                        <input type="range" min = "1" max = "10" value = "${that.opacity * 10}" class = "amap-ui-control-theme-myself-myslider">  
                        <div class="amap-ui-control-theme-myself-myslider-text" style="display:flex;justify-content: space-between;padding: 0 5px;font-size:14px;">
                        <div data-type='slider_num'>${that.opacity * 10}</div>                                                  
                        <div data-type="reset" class=amap-ui-control-theme-myself-reset>重置</div></div>
                    </div>
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _slider = html[0].querySelector("[type='range']");                        
                var _rest= html[0].querySelector("[data-type='reset']");
                let _numdiv = html[0].querySelector("[data-type='slider_num']")
                that.setOpacity = (value)=>{                   
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }                    
                }
                _rest.addEventListener('click',(e)=>{                                                                          
                    _numdiv.innerHTML = 7;
                    _slider.value = 7
                    //重置maphelper
                    that.opacity = 0.7;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(0.7)
                    }                      
                })
                _slider.addEventListener('change',(e)=>{
                    let value = e.target.value;
                    _slider.value = value;
                    _numdiv.innerHTML = value;                    
                    //重置maphelper
                    that.opacity = value/10;
                    if(that.canvasLayer){
                        that.canvasLayer.setOpacity(value/10)
                    }  
                })
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]);  

                //地点定位                
                let styleP = 'cursor:pointer;bottom:140px;left:25px;';                                                      
                                        
                var htmlstr = `<div class="amap-ui-control-container amap-ui-control-theme-myself" style="${styleP}">
                <div style="display:flex;">
                    <div class="setLocation_box" >
                        <svg type="setLocation" title="定位到站点" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                        </svg>
                    </div>                                        
                </div>
                </div>`;   
                var html = $help.toDom(htmlstr)
                var _setlocal = html[0].querySelector("[type='setLocation']");                        
                _setlocal.addEventListener('click',(e)=>{                     
                    that.map.setCenter(that.center)
                })                
                //_slider.onchange = that.opacityChange(event);
                el.appendChild(html[0]); 
            })    
        },
        addContextMenu(){
            var that = this;
             //创建右键菜单
            var contextMenu = new AMap.ContextMenu();
            //右键显示位置
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94V10ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="black"/>
            </svg>
            显示位置</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                getAddressByLngLats({lnglats:[lnglat]},function(result) {                    
                    let msg = `<div>经纬度：${lnglat[0]},${lnglat[1]}</div>
                                    <div>位置：${result[0].formattedAddress}</div>`;    
                    new AMap.InfoWindow({
                        content: msg,                        
                        }).open(that.map,lnglat);
                })                    
                
            }, 0);

              
            contextMenu.addItem(`<div class='map_contextMenu_item' style='font-size:15px;display: flex;
            align-items: center;'><svg class='ma-1' width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.35 16 12.69 15.97 13.03 15.95C13 15.8 13 15.65 13 15.5C13 14.96 13.09 14.44 13.24 13.94C12.83 14 12.42 14 12 14C9.58 14 7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V10.03C18.17 10 18.33 10 18.5 10C19 10 19.5 10.08 20 10.22V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C13.06 21 14.07 20.89 15 20.71C14.62 20.14 14.25 19.5 13.93 18.85C13.34 18.94 12.71 19 12 19M12 5C15.87 5 18 6.5 18 7S15.87 9 12 9 6 7.5 6 7 8.13 5 12 5M18.5 12C16.6 12 15 13.6 15 15.5C15 18.1 18.5 22 18.5 22S22 18.1 22 15.5C22 13.6 20.4 12 18.5 12M18.5 16.8C17.8 16.8 17.3 16.2 17.3 15.6C17.3 14.9 17.9 14.4 18.5 14.4S19.7 15 19.7 15.6C19.8 16.2 19.2 16.8 18.5 16.8Z" fill="black"/>
            </svg>
            位置数据</div>`, function () {
                let lnglat = [that.mapContextMenuPositon.lng,that.mapContextMenuPositon.lat]
                let site = that.currentSite;
                let siteCenter = [site.lng,site.lat]
                let dis = AMap.GeometryUtil.distance(lnglat,siteCenter)
                if(dis < that.scanDistance * 1000){
                    let angle = $help.bearing(siteCenter,lnglat)
                    angle = angle >= 0 ? angle :angle +360
                    angle = angle - that.currentSite.yaw < 0 ? angle - that.currentSite.yaw + 360 : angle - that.currentSite.yaw;                                        
                    if(that.lidarDatas.length > 0){
                        let min = that.lidarDatas[0]
                        let minAngle = Number(min.straightAngle) < 0 ? Number(min.straightAngle) + 360 :Number(min.straightAngle);
                        that.lidarDatas.forEach(a=>{
                            let _straightAngle = Number(a.straightAngle) < 0 ?  Number(a.straightAngle) + 360 :  Number(a.straightAngle);
                            if(Math.abs(_straightAngle - angle) < Math.abs(minAngle - angle)){
                                min = a;
                                minAngle = _straightAngle
                            }                            
                        })
                        if(Math.abs(minAngle - angle)>1){
                            let msg = `<div>点位值： </div>`                     
                            new AMap.InfoWindow({
                                content: msg,                        
                            }).open(that.map,lnglat);
                        }else{              
                            let all = min.distanceData[2] * min.distanceData[1];                         
                            let scanD = that.scanDistance/all * min.data.length;  
                            let _index = Math.round(scanD * dis/(that.scanDistance * 1000));                                 
                            _index = _index - min.distanceData[0]/min.distanceData[1];
                            if(_index > min.data.length -1){
                                let msg = `<div>点位值： </div>`                     
                                new AMap.InfoWindow({
                                    content: msg,                        
                                }).open(that.map,lnglat);
                                return
                            }
                            let _value = min.data[_index]
                            let msg = `<div>点位值：${_value} </div>`                     
                            new AMap.InfoWindow({
                                content: msg,                        
                            }).open(that.map,lnglat);
                        }
                        
                    }                                        
                }                
                else{
                    openNotice({
                        type:'error',
                        text:"所选区域超出范围"
                    })
                }
            }, 1);  

            that.map.on('rightclick', function (e) {
                contextMenu.open(that.map, e.lnglat);
                that.mapContextMenuPositon = e.lnglat;
            });            
        },              
        async draw(){ 
            if(this.lidarDatas.length == 0 || !this.map){                
                return
            }
            var that = this;   
            if(this.loadTimer){
                clearInterval(this.loadTimer)
            }          
            let _width = 400;//包含设置像素

            if(!that.canvas){
                let canvas = document.createElement('canvas')
                //canvas = this.$refs.polar;
                let ctx =canvas.getContext("2d"); 
                canvas.width = _width;
                canvas.height = _width;               
                that.canvas = canvas;
                that.ctx = ctx;
            }                           
            //let site = this.$store.state.storage_global.currentSite;            
            let arr= $help.getLayerMapPosition(Number(that.lidarDatas[0]?.angleOfPitch),this.scanDistance,this.center)            
            var bounds = new AMap.Bounds(arr[0],arr[1])   
            if(!that.canvasLayer){
                that.canvasLayer = new AMap.CanvasLayer({
                    canvas: that.canvas,
                    bounds: bounds,  
                    opacity:that.opacity,  
                    zooms: [2, 30]            
                });        
                that.map.add(that.canvasLayer);
            }else{
                if(that.canvas.width != _width){                    
                    that.canvas.width = _width;
                    that.canvas.height = _width;
                    that.canvasLayer.setCanvas(that.canvas)
                }
                that.canvasLayer.setBounds(bounds)   
                that.drawHelper(_width/2/that.scanDistance);              
                that.timeText=new Date(that.lidarDatas[that.lidarDatas.length - 1].dataTime).pattern("yyyy-MM-dd HH:mm:ss")
                if(that.canvasLayer){
                    that.canvasLayer.reFresh()
                }    
            }                      
        },
        async drawHelper(rp){  
            let yaw =this.currentSite.yaw;                         
            let _datas =this.lidarDatas.map(a=>{                
                return {
                    angle:a.straightAngle,
                    distanceData:a.distanceData,
                    data:a.data,  
                    dataTime:a.dataTime,
                }
            });  
            if(_datas.length == 0 || !this.$refs.colorBar)   {
                return;
            }
            let d = this.scanDistance;           
            var that = this;
            let distanceData =_datas[0].distanceData;
            let noDataLength =  (distanceData[0])/distanceData[1]  //无效距离   
            let scanLength =distanceData[2]*distanceData[1]       
            let data =new Uint8ClampedArray( d * rp * 2 * d * rp * 2 * 4);
            $help.drawScan(_datas,(obj)=>{
                that.$refs.colorBar.getRgb(obj.value,(color)=>{                 
                    let index = 4*(obj.y * obj.width + obj.x);                 
                    data[index] = Number(color[0])
                    data[index+1] = Number(color[1])
                    data[index+2] = Number(color[2])
                    data[index+3] = Number(color[3]) * 255      
                })                
            },()=>{
                let imageData = new ImageData(data,d * rp * 2,d * rp * 2)
                that.ctx.putImageData(imageData,0,0)  
            },noDataLength,scanLength,_datas[0].distanceData[2],{d:d,rp:rp,yaw:yaw})                                  
        },           
         loadAlarmPoints(cb){
            this.alarmDatas = []
            this.alarmList = [];
            let start = new Date(new Date().setHours(new Date().getHours() - 12)).pattern("yyyy-MM-dd HH:mm:ss") //前12个小时热图
            let end = new Date().pattern("yyyy-MM-dd HH:mm:ss")//
            var that = this;
            let alarmFilterRule =  this.currentSite.alarmFilterRule;
            let checkedId;
            if(!alarmFilterRule) return checkedId = null;
            checkedId = alarmFilterRule.filter(a=>a.checked).map(a=>a.id).join(',')  
            getAlarmPointsApi({
                id:this.currentSite.siteId,
                filter:checkedId, 
                params:{
                    StartTime:start,
                    EndTime:end,
                    PageIndex:1,
                    PageSize:1000,
                    DapTypeId:6,                    
                },
                success:(d)=>{
                    //分组 
                    let data = d.Data.sort((a,b)=>{
                        return new Date(b.DapTime) - new Date(a.DapTime)
                    }).filter(a=>{                                           
                        let rel = $help.alarmDistanceFilter(that.currentSite.lng,that.currentSite.lat,a.DapLng,a.DapLat,that.currentSite.scanDistance)                                                
                        return rel
                    }) ;
                    that.alarmDatas = data;
                    let group = []
                    for(var dt of data){
                        let item = group.find(a=>{
                            return a.DapSiteId == dt.DapSiteId && a.DapLng == dt.DapLng && a.DapLat == dt.DapLat
                        })
                        if(item){
                            item.DapLevel += dt.DapLevel;
                            item.DapCount+= dt.DapCount;
                        }   
                        else{
                            group.push(JSON.parse(JSON.stringify(dt)));
                        }
                    }     
                    that.mapHotDatas = group.map(a=>{
                        return {
                            lnglat:[a.DapLng,a.DapLat],
                            count:a.DapCount
                        }
                    })                                                        
                    //获得最近一批  
                    let alarmList = data.filter(a=>{
                        return a.DapTime == data[0].DapTime
                    })        
                    //每20个一组      
                    for(let j = 0;j< alarmList.length;){
                        let _alarmList = alarmList.slice(j,j+=20);
                        let lnglatstr= _alarmList.map(a=>{
                            return `${a.DapLng},${a.DapLat}`
                        }).join('|')  
                        getGisApi({
                            params:{
                                location:lnglatstr
                            },success:(f)=>{                                                            
                                 _alarmList.forEach(a=>{
                                    var gisInfo = f.find(b=>{
                                        var lnglat = b.GrLngLat.split(',');                                        
                                        return  Number(lnglat[0]).toFixed(4) == a.DapLng.toFixed(4) && Number(lnglat[1]).toFixed(4) == a.DapLat.toFixed(4)                                        
                                    })   
                                    if(gisInfo){
                                        var position = JSON.parse(gisInfo.GrLoc)                                                                         
                                        that.alarmList.push({
                                            time:new Date(a.DapTime).pattern('MM月dd HH:mm:ss'),
                                            lng:a.DapLng,
                                            lat:a.DapLat,
                                            position:position?.formatted_address
                                            .replace(position?.addressComponent.province,'')
                                            .replace(position?.addressComponent.city,'')
                                            .replace(position?.addressComponent.township,'')
                                            .replace(position?.addressComponent.district,''),
                                            province:position?.addressComponent.province,
                                            city:position?.addressComponent.city,
                                            street:position?.addressComponent.township,
                                            district:position?.addressComponent.district,
                                            count:a.DapCount
                                        })     
                                    }                                    
                                })
                                new AMap.InfoWindow().close();
                                //回调
                                if(cb){
                                    cb()
                                }
                            }

                        })

                    }                    
                }
            })
        },
        //热图        
        setHotLayer(show){   
            if(this.alarmDatas.length == 0 || this.alarmDatas[0].DapSiteId != this.currentSite.siteId){
                var that = this;
                this.loadAlarmPoints(()=>{                    
                    that.setHotLayer(show)
                })
                return;
            }                         
            let color = this.color;              
            if(this.mapHotLayer){
                this.map.remove(this.mapHotLayer)
            }            
            this.mapHotLayer = null;          
            //1.14版本
            this.mapHotLayer = this.map.getLayers().find(a=>{
                return a.CLASS_NAME == 'Loca.HeatmapLayer';
            });        
            if(!this.mapHotLayer){            
                this.mapHotLayer = new Loca.HeatmapLayer({
                    map:this.map
                })                
            }        
            if(show){                          
                if(this.mapHotDatas && this.mapHotDatas.length > 0){                           
                    this.mapHotLayer.setData(this.mapHotDatas, {
                        lnglat: 'lnglat',
                        value: 'count'
                    });
                }        

                let level = this.map.getZoom();
                let r = level <= 12 ? 16 : 16 + (level -12) * 5;            
                this.mapHotLayer.setOptions({ 
                    map:this.map,                  
                    style: {
                        //altitude:500000,
                        radius: r,
                        color: color
                    }
                });
                this.mapHotLayer.render();
                this.mapHotLayer.show();
            }
            else{                
                this.mapHotLayer.hide();
            }
        },
        setAlarmPoint(show){   
            var that = this;         
            if(this.alarmDatas.length == 0 || this.alarmDatas[0].DapSiteId != this.currentSite.siteId){
                 
                    this.loadAlarmPoints(()=>{
                        that.setAlarmPoint(show)
                    })
                    return;              
            }    
            if(this.alarmList.length == 0){
                let alarmList = data.filter(a=>{
                        return a.DapTime == data[0].DapTime
                    })        
                    //每20个一组      
                    for(let j = 0;j< alarmList.length;){
                        let _alarmList = alarmList.slice(j,j+=20);
                        let lnglatstr= _alarmList.map(a=>{
                            return `${a.DapLng},${a.DapLat}`
                        }).join('|')  
                        getGisApi({
                            params:{
                                location:lnglatstr
                            },success:(f)=>{                                                            
                                 _alarmList.forEach(a=>{
                                    var gisInfo = f.find(b=>{
                                        var lnglat = b.GrLngLat.split(',');                                        
                                        return  Number(lnglat[0]).toFixed(4) == a.DapLng.toFixed(4) && Number(lnglat[1]).toFixed(4) == a.DapLat.toFixed(4)                                        
                                    })   
                                    if(gisInfo){
                                        var position = JSON.parse(gisInfo.GrLoc)                                                                         
                                        that.alarmList.push({
                                            time:new Date(a.DapTime).pattern('MM月dd HH:mm:ss'),
                                            lng:a.DapLng,
                                            lat:a.DapLat,
                                            position:position?.formatted_address
                                            .replace(position?.addressComponent.province,'')
                                            .replace(position?.addressComponent.city,'')
                                            .replace(position?.addressComponent.township,'')
                                            .replace(position?.addressComponent.district,''),
                                            province:position?.addressComponent.province,
                                            city:position?.addressComponent.city,
                                            street:position?.addressComponent.township,
                                            district:position?.addressComponent.district,
                                            count:a.DapCount
                                        })     
                                    }                                    
                                })
                                that.setAlarmPoint(show)                                
                            }
                        })
                    }
            }else{
                let datas = show?this.alarmList.map(a=>{
                    return [a.lng,a.lat,a.time,a.count,a.position,a.province,a.city,a.district,a.street]
                }):[];
                let series = [{
                    type: 'scatter',
                    coordinateSystem: 'amap',
                    data:datas,
                    labelLine:{
                        show:true,
                        length2:30
                    },
                    label:{
                        position:['left'],   
                        color:"#ff0000",   
                        textBorderColor:'#fff',
                        textBorderWidth:1,
                        show:true,
                        width:200,
                        overflow:'break',
                        formatter:(a)=>{                        
                            return `${a.data[2]}${a.data[4]}报警${a.data[3]}次`;
                        }
                    },
                    labelLayout:function (e) {                    
                        if(e.dataIndex > 5){
                            return {
                            x: that.mapEchart.getWidth() - 260,
                            y: Number((e.dataIndex) * 40) + 60,                  
                            moveOverlap: 'shiftY'
                            }
                        }else{
                            
                            return {
                                x: 40,
                                y: Number((e.dataIndex) * 40) + 60,                    
                                moveOverlap: 'shiftY'
                            }
                        }                   
                    }
                }] 
                let site = this.currentSite;
                let center = [site.lng,site.lat]
                this.center = center;
                let option = {
                    amap: {
                        // 高德地图中心经纬度
                        center:center ,
                        rotateEnable:false,
                        pitchEnable:false,   
                        isHotspot:false,             
                        pitch: 0,
                        rotation: 0,//-55
                        viewMode:'3D',//使用3D视图                        
                        // 启用resize
                        resizeEnable: true,
                        // 移动过程中实时渲染 默认为true 如数据量较大 建议置为false
                        renderOnMoving: true,
                        echartsLayerInteractive: true,
                        largeMode: false,
                        returnMapCameraState: true,
                        // 自定义地图样式主题
                        //53dd45a35fd6d89a2303f51cad6cce14                    
                    },
                    series:series
                }
                this.mapEchart.setOption(option);                
                this.map = this.mapEchart.getModel().getComponent('amap').getAMap();  
            }                        
        }
    }
}
</script>

<style scoped lang="scss">
    .monitorItemBox{
        // width: fit-content !important;
    } 
    .mb-map{
        height: 100%;
      
    }
    .weather{
        position: absolute;
        display: flex;
        z-index: 1;
        left: 0px
    }
    .timeText{
        position: absolute;
        z-index: 1;
        top: 30px;
        left: 5px;
    }
    .mapColorBar{
        z-index: 1;
        position:absolute;
        top: 58px;
        right: 20px;    
    }
    .hotBtn{
        z-index: 9;
        position: absolute;
        right: 15px;
        bottom: 5px;
        :deep(.v-input__slot){
            flex-direction: row-reverse;
            justify-content: space-between
        }
    }
    //比例尺
:deep(.amap-scalecontrol){
    bottom:0px;
    left:20px;
    z-index: 1;
}
:deep(.amap-ui-control-theme-myself .amap-ui-control-layer) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--v-fTheme-base);
    color:var(--v-rTheme-base);
    i{
        color:var(--v-rTheme-base);
    }
}
.map_marker{
  path{
      fill:var(--v-rTheme-base);
    }
}
:deep(.amap-ui-control-theme-myself){
    z-index: 1;
  .amap-ui-control-layer-slider{
    background: var(--v-fTheme-base);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  }
  .amap-ui-control-layer-expanded {    
    color:var(--v-rTheme-base);
    background: var(--v-fTheme-base);
  }
  .amap-ui-control-layer-toggle{    
    color:var(--v-rTheme-base);    
  }
  ._slider{
    display: none;
    padding:5px;
    color:var(--v-rTheme-base);   
  }
  .opacity_box{
    display: flex;
  }
  .amap-ui-control-layer-slider:hover svg{
    display: none;
  }
  
  .amap-ui-control-layer-slider:hover ._slider{
    display: block;
  }
  path{
    fill:var(--v-rTheme-base);
  }
} 
 
.mapColorBarConfig{
    position: absolute;
    top: 0px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.monitorpointCard{
    position: absolute;
    z-index: 999;
    right: 0px;
    top: 0px;
}

.markIcon{
    position:absolute;
    bottom: 30px;
    border-radius: 5px;
    left: 150px;
}
</style>