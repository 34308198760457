<template>
    <v-container class="containChartBox width100 height100 ma-0 pa-0 ">        
    <v-overlay
          absolute
          :value="overlay"
        >
       <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>    
    <v-card  class="width100 height100 ma-0 d-flex flex-column">
        <ColorBarConfig @update="timerDraw" dataKey="97" ref="colorBar" class="mapColorBarConfig" />       
        <v-overlay
        class="ma-3 mt-8"
            absolute
            :value="noData"
            opacity="0.2"
            >
        暂无数据
        </v-overlay>    
        <v-card-title class="my-0 py-0">PRR</v-card-title>    
        <div ref="chart" class="width100 plotychart" style="height: calc(100% );position: relative">
        </div>
    </v-card>
</v-container>
</template>
<script>
import Plotly from 'plotly.js-dist'
import ColorBarConfig from '@/components/colorBar/ColorBarConfig'
import { getVwlsDVCMApi,getVwlsRcsDataApi } from '@/api'
import transferDataType from '@/plugins/datas/transferDataType'
export default {
    name:"heatmap_history_vwls",
    components:{ColorBarConfig},
    props:{
        currentView:{
            type:String,
            default:"PPI"
        },
        timeRange:{
            type:Array
        },
    },
    data(){
        return {
            overlay:false,           
            chart:null,
            width:370,
            height:268,
            data:[],
            ablData:[],
            showVertical:false,
            timer:null, 
            currentScanType:"PPI",  
            currentWatch:null         
        }
    },
    computed:{   
        noData(){
            return !this.overlay && (!this.data || this.data.length == 0) 
        },
    },
    watch:{  
        timeRange:{
            handler(n){                        
                if(n.length > 0){                                                            
                    this.data = []  
                    this.load()
                }
            },
            deep:true
        } ,
        currentView:{
            handler(n,v){
                this.currentScanType = n
                this.load();    
            }
        },      
        "$store.state.storage_global.currentSite":{
            handler(n,v){    
                this.datas = []
                let that = this; 
                if(!n.scanType){
                    this.$store.dispatch('storage_user/getLastestScanType',{
                        site:n,
                        cb:(obj)=>{
                            if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                                that.currentScanType = obj.scanType
                                that.load();          
                            }
                        }
                    })
                }else{
                    this.load();                            
                }                    
            }
        },               
        "$vuetify.theme.isDark":{
            handler(n,v){
                this.loadChart()                    
            }
        },
        currentScanType:{
            handler(n){
                this.loadChart()    
            }
        }
    },
    mounted(){
        let that = this;
        if(!that.$store.state.storage_global.currentSite.scanType){
            this.$store.dispatch('storage_user/getLastestScanType',{
                site:that.$store.state.storage_global.currentSite,
                cb:(obj)=>{
                    if(obj.siteId == that.$store.state.storage_global.currentSite.siteId){
                        that.currentScanType = obj.scanType
                        that.load();          
                    }
                }
            })
        }else{
            this.load();                            
        }               
        //this.load()      
    },
    methods:{
        load(){      
            if(!this.timeRange || this.timeRange.length == 0){
                return                
            }        
            let siteId = this.$store.state.storage_global.currentSite.siteId;                 
            this.data = [];
            this.ablData = [];
            let start = this.timeRange[0].pattern("yyyy-MM-dd HH:mm:ss") ;
            let end = this.timeRange[1].pattern("yyyy-MM-dd HH:mm:ss") 
            //扫描模式不同 查询不同数据
            if(this.currentScanType == 'PPI' || this.currentScanType == "RHI"){
                this.loadPrrData(start,end,siteId)
            }else if(this.currentScanType == 'RHI' || this.currentScanType == "Qua"){
                this.loadPrrData(start,end,siteId)
                
                this.loadCAData(start,end,siteId);
            }            
        },  

        loadCAData(start,end,siteId,datas = []){
            let that = this;
            getVwlsDVCMApi({
                id:siteId,  
                params:{
                    PageSize:2500,                        
                    StartTime:start,
                    EndTime:end,
                    ScanMode:this.currentScanType== "FIX" ? 1 : this.currentScanType== "PPI" ? 0 : this.currentScanType== "RHI" ? 2:this.currentScanType== "Qua" ? 4 : 0,
                },success:(d)=>{ 
                    if(!d?.Data ||d.Data.length == 0){
                        that.overlay = false
                    }                       
                    let _datas = d.Data.map(a=>{
                        return {
                            blh:a.DvcaAblHeight,//边界层
                            cb:a.DvcaCloudBottom,//云底
                            ct:a.DvcaCloudTop,//云高
                            ch:a.DvcaCloudHeight,//云厚
                            angleOfPitch: a.DvcaPitch,                                                  
                            dataTime:a.DvcaTime?.replace('T',' '),
                            dataType:100,
                            period:a.DvcaPeriod?.split('_')[0],
                            yaw:a.DvcaPeriod?.split('_')[1],
                            siteId:a.DvcaSiteId,
                            scanType:transferDataType[a.DvcaScanMode],
                            straightAngle:a.DvcaStraight
                        }})   

             
                    datas = _datas.concat(datas)           
                    if(new Date(d.Data[d.Data.length -1].DvcaTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Data.length < 1000){                                   
                        that.ablData = datas;                        
                        that.loadChart()  
                        that.overlay = false        
                    }else{                              
                        that.ablData = datas;     
                        that.loadCAData(start,d.Data[d.Data.length -1].DvcaTime.replace('T',' '),siteId,datas)                                  
                    }    
                }
            })
        },       
        loadPrrData(start,end,siteId,datas = []){              
            var that = this;
            that.overlay = true
            getVwlsRcsDataApi({
                id:siteId,                 
                params:{
                    PageSize:2500,                        
                    StartTime:start,
                    EndTime:end,
                    ScanMode:this.currentScanType== "FIX" ? 1 : this.currentScanType== "PPI" ? 0 : this.currentScanType== "RHI" ? 2:this.currentScanType== "Qua" ? 4 : 0,
                },success:(d)=>{ 
                    if(!d?.Data ||d.Data.length == 0){
                        that.overlay = false
                        that.loadChart() 
                    }                       
                    let _datas = d.Data.map(a=>{
                        return {
                            siteId:a.DvrSiteId,
                            period:a.DvrPeriod,
                            scanType:a.DvrScanMode,
                            dataTime:a.DvrTime.replace("T"," "),                            
                            angleOfPitch:a.DvrPitch,
                            straightAngle:a.DvrStraight,
                            distanceData:a.DvrDis,
                            data:a.DvrValue
                        }})   
                    datas = _datas.concat(datas)   
                                        
                    if(new Date(d.Data[d.Data.length -1].DvrTime.replace('T',' ')).getTime()  <= new Date(start).getTime() || d.Data.length < 1000){                                   
                        that.data = datas;                        
                        that.loadChart()  
                        that.overlay = false        
                    }else{                              
                        that.data = datas;                  
                        that.loadChart() 
                        that.loadPrrData(start,d.Data[d.Data.length -1].DvrTime.replace('T',' '),siteId,datas)                                  
                    }    
                }
            })
        },       
        async loadChart(){        
            let target = this.$refs.chart; 
            let that = this;                
            let bgColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.black:this.$vuetify.theme.defaults.light.white// ? 'black':'white';            
            let fontColor = this.$vuetify.theme.isDark? this.$vuetify.theme.defaults.dark.white:this.$vuetify.theme.defaults.light.black            
            if(this.data.length == 0 || !this.$refs.colorBar){                
            var data = [ 
                {
                y: [],
                x: [],
                z: [],
                type: 'heatmap',           
                showscale: false,                                            
            },{
                type: 'scatter',               
                x: [],
                y: [],                              
                showlegend: false,                
            },{
                type: 'scatter',               
                x: [],
                y: [],                              
                showlegend: false,                
            }
            ];

            var layout = {
            // title: 'Annotated Heatmap',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,     
                xaxis: {
                    color:fontColor,    
                    nticks:$help.getNtick(target.clientWidth)      
                },
                yaxis: {
                    title:'距离(km)',
                },
                yaxis2: {
                    domain: [0.6, 0.95],
                    anchor: 'x2'
                },
                // xaxis2: {
                //     domain: [0.6, 0.95],
                //     anchor: 'y2'
                // },
                modebar:{
                    add:[],
                    remove:['select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:10,
                    l:32,
                    r:42
                }
            };
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});   
                return
            }
            let _datas =[]; 
            let dataList = this.data.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime());
            let minSpanList = [];  
            dataList.forEach((a,i)=>{
                // if(i == 0){
                //     minSpan = Math.abs(new Date(this.dataList[i].dataTime).getTime() -new Date(this.dataList[i+1].dataTime).getTime())/1000/60
                // }
                if(i<dataList.length -1){
                    let _minspan = Math.abs(new Date(dataList[i].dataTime).getTime() -new Date(dataList[i+1].dataTime).getTime())/1000/60
                    //minSpan = minSpan < _minspan ? minSpan:_minspan
                    minSpanList.push(_minspan)
                }
            })
            minSpanList = minSpanList.sort((a,b)=>a-b)
            let minSpan = minSpanList[Math.floor(minSpanList.length/2)]
            minSpan+=1;    
            for(let i in this.data.sort((a,b)=>new Date(b.dataTime).getTime() - new Date(a.dataTime).getTime())){
                if(i > 0){
                    let span = Math.abs((new Date(this.data[i].dataTime).getTime() - new Date(this.data[Number(i)-1].dataTime).getTime()) / 1000/60);
                    if(span > minSpan){
                        let j = 0;
                        while(j<span){                                            
                            j+=minSpan;                      
                            _datas.push({
                                siteId:this.data[i].siteId,
                                period:this.data[i].period,
                                scanType:this.data[i].scanType,
                                dataTime:new Date(new Date(this.data[i].dataTime).setMinutes(new Date(this.data[i].dataTime).getMinutes() + j)).pattern("yyyy-MM-dd HH:mm:ss"),
                                angleOfPitch:this.data[i].angleOfPitch,
                                mergeType:this.data[i].mergeType,
                                straightAngle:this.data[i].straightAngle,
                                distanceData:this.data[i].distanceData,
                                data:Array.from({length: this.data[i].data.length}, (val, i) => NaN) 
                            })   
                        }
                    }
                }
            }
            _datas = _datas.concat(this.data)
            let datas = _datas.sort((a,b)=>{
                return new Date(a.dataTime).getTime() - new Date(b.dataTime).getTime()
            });

            let yData = [];               
            yData = [];       
            let distanceData =datas[0].distanceData;            
            let noDataLength =  -distanceData[0] /  distanceData[1];//无效距离        
            for(let i = noDataLength;i< datas[0].distanceData[2];i++){                
                let y = datas[0].distanceData[0] + i* datas[0].distanceData[1]
                yData.push(y.toFixed(2))
            }    
            let xData = []; 
            let zData = [];
            let hoverText = [];
            let yAbl = [];   
            let cbxData = [],cbyData = []
            let chData = []
            datas.forEach((a,i)=>{                    
                xData.push(new Date(a.dataTime))                
                for(var j=0;j< yData.length;j++){
                    if(!zData[j]){
                        zData[j] = [];
                        hoverText[j] = []
                    }          
                    let _index = j+noDataLength  
                    zData[j].push(a.data[_index])
                    hoverText[j].push(`水平角：${a.straightAngle}<br />俯仰角：${a.angleOfPitch}<br />监控值：${a.data[_index]}<br />距离：${yData[j]}<br />时间：${new Date(a.dataTime.replace("T"," ")).pattern("yyyy年MM月dd HH:mm:ss")}` )
                }   
                let item = this.ablData.find(b=>b.dataTime == a.dataTime)                    
                if(item){
                    yAbl.push(item.blh)
                    chData.push(item.ch)
                    item.cb.forEach(c=>{
                        cbxData.push(a.dataTime)
                        cbyData.push(c)
                    })
                }else{
                    yAbl.push(NaN)
                }                                                                        
            });
            var data = [ 
                {
                    name:'',
                    y: yData,
                    x: xData,
                    z: zData,
                    type: 'heatmap',           
                    showscale: false,            
                    hovertext:hoverText,
                    yaxis: 'y2',        
                    colorscale:this.$refs.colorBar.colorScaleValue,
                    zmin:this.$refs.colorBar.rangeInfo[0].min,
                    zmax:this.$refs.colorBar.rangeInfo[this.$refs.colorBar.rangeInfo.length-1].max
                },{
                    name:'边界层',
                    type: 'scatter',               
                    x: xData,
                    y: yAbl,                              
                    showlegend: true,   
                    yaxis: 'y2',                     
                    text:yAbl
                },{
                    name:"云底",
                    type: 'scatter',   
                    mode: 'markers',            
                    x: cbxData,
                    y: cbyData,                              
                    showlegend: true, 
                    yaxis: 'y2',                       
                    text:cbyData

                },{
                    name:"云厚",
                    type: 'scatter',           
                    x: xData,
                    y: chData,
                                             
                    showlegend: true,                    
                    text:chData
                }
            ]; 
            let maxch = Math.max(...chData)   
            var layout = {
            // title: 'Annotated Heatmap',
                plot_bgcolor: bgColor,
                paper_bgcolor: bgColor,
                legend: {
                    orientation:"h",
                    x: 1,
                    xanchor: 'right',
                    y: 1.11,
                    font:{
                        color: fontColor
                    }
                },     
                xaxis: {
                    color:fontColor,     
                    tickformat:"%H:%M <br /> %m月%d日 ",
                    nticks:$help.getNtick(target.clientWidth)      
                },
                yaxis2: {
                    title:'距离(km)',
                    domain: [0.34, 1],
                },
                yaxis: {
                    domain: [0, 0.28],    
                    range:[0,Math.floor(maxch * 1.2)]                
                },
                // xaxis2: {
                //     domain: [0, 0.98],
                //     anchor: 'y2',
                //     tickformat:"%H:%M <br /> %m月%d日 ",
                //     color:fontColor,    
                //     nticks:$help.getNtick(target.clientWidth) 
                // },
                modebar:{
                    add:[{
                        name: 'download image',
                        icon: Plotly.Icons.camera,
                        direction: 'download',
                        click: function(gd,event) {
                            let w = gd.clientWidth;
                            let h = gd.clientHeight;
                            Plotly.toImage(gd).then(function(dataUrl) {                                
                                $help.imgDownLoad(dataUrl,w,h,that.$refs.colorBar.$el.children[0],bgColor)                               
                            })                           
                        }
                    }],
                    remove:['toimage','select','select2d','lasso','lasso2d']
                },
                margin:{
                    t:45,
                    l:42,
                    r:55,
                    b:40
                }
            };
            if(target.children.length> 0){
                let mainSvg = target.querySelector('.main-svg');                   
                if(target.clientHeight != mainSvg.clientHeight || 
                target.clientWidth != mainSvg.clientWidth ) {                                            
                    Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});  
                }else{
                    Plotly.react(target, data, layout,{responsive: true,scrollZoom: true});
                }                               
            }else{
                Plotly.newPlot(target, data, layout,{responsive: true,scrollZoom: true});               
            }                
        },
        //重置大小
        resize(){
            var that = this;
            //监听鼠标移动
            let fun = (e)=>{
                //重设表格大小
                let resetW = Number(that.width) - e.movementX ;
                let resetH = Number(that.height) + e.movementY;
                that.width = resetW > 0.4 * document.body.clientWidth ? 0.4 * document.body.clientWidth : resetW < 370 ? 370 : resetW;
                that.height = resetH > 0.4 * document.body.clientHeight ? 0.4 * document.body.clientHeight : resetH < 260 ?260 : resetH;
                localStorage.setItem("historyPLSHotHeight",that.height)
                localStorage.setItem("historyPLSHotWidth",that.width)
                that.$store.commit("storage_global/updatewidthHeight",`${that.width},${that.height}`)          
            }
                document.addEventListener('mousemove',fun )
                document.addEventListener('mouseup', ()=>{                                    
                document.removeEventListener('mousemove',fun)
            });
        },  
        timerDraw(){  
            if(this.data.length == 0){
                return
            }
            var that = this;
            if(this.timer){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                //that.ctx.clearRect(0,0,that.canvas.width,that.canvas.height)        
                //that.drawHelper()
                that.loadChart()
            }, 1000/3);
        }      
    }
}
</script>
<style scoped lang="scss">
.containChartBox{
    position: relative;
    max-width: 100%;
    max-height: 100%;
}

.mapColorBarConfig{
    position: absolute;
    top: 50px;
    right: 10px;
    width: 30px;
    height: 30px;
    z-index: 2;
    height: 80%;
}
.plotychart{
    :deep(.modebar){
        padding-right:40px;
        top: -20px;
    }
}
</style>
