<template>
<div class="mainBody">    
    <!--消息提醒  -->
    <v-snackbar
        absolute
      v-model="snackbar.show"
      top
      class="ma-6"
      style="z-index:10000"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          {{$i18n.t("layout.btn.close")}}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog 
    v-model="videoDialog"
      width="840">
        <v-card width="840">
            <vue-core-video-player ref="player" :src="videoSrc"></vue-core-video-player>        
        </v-card>
    </v-dialog>
    <v-app-bar :style="$store.state.storage_user?.user?.extendInfo?.hideTop ? 'display:none' : ''" width="100%" max-width="100%" min-width="1200px" clipped-left dense  app :color="$vuetify.theme.currentTheme.primary"> 
        <v-row class="white--text ma-0 pa-0 flex-nowrap width100 height100">
            <v-col cols="2" class="justify-left d-flex align-center" style="min-width:216px;font-size: 14px;">
                <div class="d-flex align-end flex-grow-1 flex-shrink-0"><v-img class="flex-shrink-0 flex-grow-0" max-width="12vw" min-width="36px" max-height="30px" :src="logoPath"></v-img>{{$i18n.t('layout.title')}}</div>
            </v-col>   
            <v-spacer></v-spacer>     
            <v-col  cols="5" class="justify-left d-flex flex-nowrap pa-1">
                <v-row class="ma-0 pa-0 width100" no-gutters>
                    <v-col cols="6" v-show="showSiteTypeSelect">
                        <v-menu open-on-hover offset-y bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn   
                                class="white--text"
                                text                                                             
                                v-bind="attrs"
                                v-on="on"
                                ><v-icon class="ml-4 mr-4">{{currentType.icon}}</v-icon>
                                {{currentType.code == 0 ?$store.state.storage_user?.user?.extendInfo?.alias?.siteType?.pls ?$store.state.storage_user?.user?.extendInfo?.alias?.siteType?.pls:currentType.name:currentType.name}}
                                </v-btn>
                            </template>
                            <v-list shaped>                                
                                <v-list-item-group v-model="currentType">
                                    <v-list-item
                                        v-for="(item, index) in siteTypes"
                                        :key="index" 
                                        :value="item"                                        
                                    >
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </v-col>
                    <v-col cols="6" v-show="showSiteSelect">
                         <v-menu open-on-hover offset-y :close-on-content-click="false" bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn                                  
                                class="white--text width100 siteSelect"
                                text                                                             
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon  class="ml-4 mr-4">mdi-map-marker-radius</v-icon>
                                <v-badge
                                right                                
                                :value="currentSite.scanType"                                                
                                :color="currentSite.commitStatus == 1 ? 'green':'grey'"
                                inline
                                :content="currentSite.scanType">
                                <div style="text-align:left;max-width: calc(100% - 64px);text-overflow: ellipsis;overflow: hidden;">{{currentSite.siteName}}{{currentSite.transStatus == 1 ? $i18n.t("layout.label.stop"):currentSite.commitStatus == 0 && currentSite.type != 3 ? $i18n.t("layout.label.outline"):""}}</div>
                                </v-badge>
                                </v-btn>
                            </template>
                            <v-sheet  class="topSheet pa-3 mb-1 primary">
                                <v-text-field
                                    v-model="search"
                                    dark
                                    flat
                                    solo-inverted
                                    hide-details
                                    dense
                                    clearable
                                    append-icon="mdi-magnify"
                                    clear-icon="mdi-close-circle-outline"
                                ></v-text-field>              
                            </v-sheet>
                            <v-card  max-height="400" class="mt-15" style="overflow:auto">
                                <v-list class="pt-0" shaped>                                
                                    <v-list-item-group >                                    
                                        <v-list-item
                                            v-for="(item, index) in sites"
                                            :key="index" 
                                            :value="item" 
                                            @click="currentSite = item"                                       
                                        >
                                        <v-list-item-title :class="{'grey--text':item?.commitStatus == 0 && item?.type != 3}" class="width100 d-flex justify-space-between">
                                            {{ item?.siteName }}<v-spacer class="mx-2"></v-spacer>{{ item?.transStatus == 1 ? $i18n.t("layout.label.stop"): (item?.commitStatus == 0 && item?.type != 3) ? $i18n.t("layout.label.outline"):""}}</v-list-item-title>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>                            
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" class="justify-end d-flex  align-center" style="min-width:292px;">
                <v-menu open-on-hover offset-y left z-index="100">
                    <template v-slot:activator="{ on, attrs }">
                        <v-badge
                            :value="message.length > 0 "                   
                            color="pink"
                            overlap
                            :content="message.length"
                        >
                            <v-icon v-on="on" v-bind="attrs" @click="$router.push({name:'noticeManage.index'});message=[];" class="white--text mx-2">mdi-bell</v-icon>
                        </v-badge>
                    </template>
                    <v-list max-width="400px">                        
                        <v-list-item>
                            <v-list-item-title>{{$i18n.t("layout.label.message")}}</v-list-item-title><v-icon color="#e99d1e" class="mx-2">mdi-bell-alert</v-icon>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-row class="ma-0 pa-0" style="max-height:280px;min-width:200px;overflow:auto;">
                        <v-list-item class="my-2 pointer" v-for="i,key in message.slice(0,6)" :key="key">{{i.siteId ? `${i.date}${i.position?.formatted_address}发现污染物，报警次数${i.count}`:i}}</v-list-item>                        
                        </v-row>
                        <v-row class="ma-0">
                            <v-spacer></v-spacer>
                            <v-btn class=""  @click="$router.push({name:'noticeManage.index',params:{toRoute:'warning'}});message=[];" text color="primary">{{$i18n.t("layout.btn.more")}}</v-btn>
                        </v-row>                        
                    </v-list>
                </v-menu>                
                <span class="mx-8 pointer align-center d-flex" @click="$router.push({name:'userInfo.index'})">
                    <v-avatar 
                        size="32" 
                        class="mx-2"             
                        >
                        <v-img :src="avatorUrl"></v-img>
                    </v-avatar>
                    <span style="min-width: 40px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap">{{$store.state.storage_user.user.userName}}</span>
                </span>
                <span  v-show="!$store.state.storage_user.user?.company?.extendInfo?.hideHelp"><v-icon  class="white--text mx-2" @click="playVidio">mdi-help-circle</v-icon></span>
                <v-menu open-on-hover offset-y left z-index="100">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-show="false"  v-on="on" v-bind="attrs" @mouseenter="getQr" class="white--text mx-2">mdi-alpha-g-box</v-icon>                        
                    </template>
                    <v-card width="140" height="160" class="d-flex flex-column align-center justify-center">                            
                        <div ref="qrcode" class="qrcode mt-1"></div>            
                        <v-btn text color="primary" class="mt-1" >了解我们</v-btn>
                    </v-card>
                </v-menu>
                <span style="min-width: 52px;" v-show="notLink" class="ma-2 pointer" @click="logout">{{$i18n.t("layout.btn.logout")}}<v-icon dense class="white--text">mdi-logout</v-icon></span>              
            </v-col>
            <ScrollNoticeTopPlusTwo />
        </v-row>       
    </v-app-bar>     
    <v-navigation-drawer 
        class="elevation-8"
        :style="`min-height:752px;${$store.state.storage_user?.user?.extendInfo?.hideTop ? 'height:100vh;top:0;max-height:100vh;' :'height:calc(100vh - 48px)'}`"
        :mini-variant="sliderMenuMini"
        permanent                        
        app    
        clipped        
    >
        <div class="hideBtn"><v-app-bar-nav-icon class="float-right mt-4" @click="sliderMenuMini=!sliderMenuMini"></v-app-bar-nav-icon></div>
        <v-list dense nav>
            <v-list-item-group mandatory :value="activeName"  link active-class="groupActive" :color="$vuetify.theme.currentTheme.primary">  
                <span v-for="(item, key) in menus" :key="key" :title="item.title">
                    <v-list-item                          
                        class="sliderNav mt-1"
                        shaped v-if="!item.children && item.show"                    
                        @click="goto(item)"
                        :value="item.activeName"
                        >
                        <v-list-item-icon style="margin-right:20px">
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="font-size16" v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group :value="showSub(item)"  v-if="item.children && item.show" class="sliderNavGroup" link  >
                        <template v-slot:activator>                        
                            <v-list-item-icon  style="margin-right:20px">
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                            <v-list-item-title class="font-size16" v-text="item.title"></v-list-item-title>
                            </v-list-item-content>                        
                        </template>
                        <v-list-item v-show="subItem.show" class="sliderNav" :value="subItem.activeName" style="padding-left:20%;" shaped v-for="(subItem, k) in item.children"  :key="k" @click="goto(subItem)">                                                               
                            <v-list-item-icon  style="margin-right:20px">
                                <v-icon v-text="subItem.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                            <v-list-item-title  v-text="subItem.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>                    
                    </v-list-group>
                </span>                                
            </v-list-item-group>        
        </v-list>       
    </v-navigation-drawer>               
    <!-- 颜色主题设置 -->    
    <v-navigation-drawer        
        :value="themeConfigShow"
        @input="setThemeConfigShow"
        right
        top
        absolute
        app    
        clipped 
        temporary
        class="theme-setting"
    >   
        <div class="themeConfigBody">
            <div class="d-flex align-end">
                <v-switch
                v-model="$vuetify.theme.dark"
                dense
                @change="setDark"
                hide-details
                inset                
                ></v-switch>
                <v-icon v-text="$vuetify.theme.dark ? 'mdi-brightness-4':'mdi-brightness-6'"></v-icon>{{ $vuetify.theme.dark ? $i18n.t('layout.label.themeDark'):$i18n.t('layout.label.themeLight')}}
            </div>
            
            <div class="themeColors">
                {{this.$i18n.t('layout.label.themeConfigTitle')}}
                 <v-chip-group
                    :value="themeColor"
                    mandatory
                    column
                    active-class="colorChipActive elevation-8"                    
                    >
                    <v-chip class="colorChip" :value="color" :color="color" v-for="color in colors" :key="color" @click="setThemeColor(color)">
                    </v-chip>
                </v-chip-group>
            </div>
        </div>       
        <div ref="iconConfig" class="iconConfig" @mousedown="setPosition" :style="`left:${configIconPosition.left}px;top:calc(${configIconPosition.top} + ${configIconPosition.offsetY}px)`" @click="themeConfigShow=!themeConfigShow"><v-icon color="#fff">mdi-cog</v-icon></div>      
    </v-navigation-drawer>
    <v-main :style="$store.state.storage_user?.user?.extendInfo?.hideTop ? 'padding:0px 0px 0px 256px' : ''">     
        <router-view class="view-box">                             
        </router-view>                    
    </v-main> 
          
</div>
    
</template>
<script>
import QRCode from 'qrcodejs2'
import anime from 'animejs'
import colors from '@/plugins/datas/themeColors.json'
import menuJson from '@/plugins/datas/menu.json'
import themColorHelper from '@/plugins/helper/themeColorHelper'
import ScrollNoticeTopPlusTwo from '@/components/notice/ScrollNoticeTopPlusTwo'
import {getSiteExchangeStringAsync,getRefreshTokenApi,getGisApi,loginOutApi,getAlarmPointComputedsApi} from '@/api'
import siteTypeWithRoute from '@/plugins/datas/siteTypeWithRoute'
import {uploadMinioURL} from '@/api/url'
import  Stomp from 'stompjs';
import sysconfig from '@/plugins/datas/sysconfig.js'
import transferHelper from '@/plugins/helper/transferHelper'
export default {
    name:"layout.mainLayout",
    components:{ScrollNoticeTopPlusTwo},
    data(){
        return {  
            videoSrc:sysconfig.promotionalDocuments,
            search:"",     
            videoDialog:false,
            sliderMenuMini:false,
            themeConfigShow:false,
            themeColor:"",   
            activeName:"",
            currentType:{
                name:'',
                icon:'mdi-atom-variant'
            },  
            currentSite:{},
            message:[],
            //消息定时 防止过度加载地理位置
            messageGisTimmer:null,
            webSocketClient:null,            
            //消息提醒
            snackbar:{
                show:false,
                text:""
            },
            configIconPosition:{
                left:-50,
                top:'50%',
                offsetX:0,
                offsetY:0
            },
            dataUpdateInfo:[],
            reconnectTime:0 
        }
    },
    watch:{
        //添加动画效果
        themeConfigShow:{
            handler(n){
                let currrentTarget = this.$refs.iconConfig;
                if(n){
                    anime({
                        targets:currrentTarget,
                        left:"-12px",
                        rotate:50,
                        easing: 'easeInOutQuad',
                        duration: 500  
                    })
                }else{
                    anime({
                        targets:currrentTarget,
                        left:this.configIconPosition.left,
                        rotate:0,
                        easing: 'easeInOutQuad',
                        duration: 500  
                    })
                }
            }
        },
        "$route.path":{
            handler(n){                               
                //设置活动导航   
                this.activeName =  this.$router.currentRoute.meta.activeName;
                let _types = siteTypeWithRoute.filter(a=>{
                    return a.route.indexOf(n) >= 0;
                })            
                if(_types.length > 0){
                    //切换站点类型
                    let item = _types.find(a=>{
                        return a.type == this.currentType.code
                    })                                         
                    // console.log(item,_types,this.currentType)
                    if(!item){
                        let _currentType = this.siteTypes.find(a=>{
                            return a.code == _types[0].type
                        })                       
                        if(_currentType){
                            this.currentType = _currentType                            
                        }
                    }
                }                
            }
        },
        currentType(n){  
            let isInMonitoringRoute = false;           
            let _routes =[]            
            siteTypeWithRoute.forEach(a=>{
                if(a.route.indexOf(this.$route.path) >=0){
                    isInMonitoringRoute = true;
                }
                if(a.type==n.code){
                    _routes = a?.route  
                }
            })                                  
            if(_routes && _routes.indexOf(this.$route.path) < 0 && isInMonitoringRoute){
                //切换站点类型                
               this.$router.push({path:_routes[0]})
            }    
            localStorage.setItem("currentType",JSON.stringify(n))                 
            this.$store.commit("storage_global/updateCurrentType",n,{root:true})
        },
        "$store.state.storage_global.currentSite":{
            handler(n,v){                    
                this.currentSite = n                                
            }
        },        
        currentSite:{
            handler(n){
                if(n){                                                     
                    this.$store.commit('storage_global/updateCurrentSite',n,{root:true})                    
                }                
            }
        },
        videoDialog(n){
            if(n){
                this.$refs.player?.play()
            }else{
                this.$refs.player?.pause()
            }
        }
    },   
    beforeDestroy()
    {
        if(timer){
            clearInterval(timer);
        } 
        if(this.messageGisTimmer){
            clearTimeout(this.messageGisTimmer)
        }
        if(this.webSocketClient){
            this.webSocketClient.disconnect()
            this.webSocketClient = null;
        }             
    },
    mounted(){          
        this.minCssConfig(true) 
        this.currentType =localStorage.getItem('currentType')?JSON.parse(localStorage.getItem('currentType')): this.siteTypes[0]          
        //设置头像
        if(localStorage.getItem("avatarPath")){
            this.$store.commit("storage_global/updateAvatarPath",localStorage.getItem("avatarPath"),{root:true})
        }   
        //设置主题按钮位置
        if(localStorage.getItem("configIconOffsetY")){
            this.configIconPosition.offsetY = Number(localStorage.getItem("configIconOffsetY"))
        }
        //监听消息
        this.watchingNotice()  
        var that = this;                 
        window.timer=setInterval(()=>{
            that.reconnectTime = 0
            getRefreshTokenApi({           
                success:(d)=>{                         
                    localStorage.setItem("token",d.token)
                    that.$store.dispatch('storage_user/getCurrentUser') 
                }
            }); 
        }
        , 1000*60*5);     
        //设置活动导航
       
        if(this.$store.state.storage_global.message.length > 0){
            this.message = this.$store.state.storage_global.message.slice(0,6)
        }         
        this.activeName = this.$router.currentRoute.meta.activeName         
        let str = localStorage.getItem("currentTheme")
        if(str == null || str == ""){
            this.themeColor =  this.$vuetify.theme.themes.light.primary
            return;
        }                
        let currentTheme = JSON.parse(str)
        if(currentTheme.dark){
            this.$vuetify.theme.dark = currentTheme.dark
        }        
        if(currentTheme.color){
            this.setThemeColor(currentTheme.color)            
            this.themeColor = currentTheme.color            
            return;
        }        
        this.themeColor = this.$vuetify.theme.themes.light.primary   
    },                
    computed:{
        showSiteSelect(){            
            let showSiteSelectRoutes = ['/monitoring/pls/realtime','/monitoring/pls/history','/monitoring/vls/realtime','/monitoring/vls/history','/monitoring/wls/history','/monitoring/wls/realtime','/monitoring/agt/history','/monitoring/vwls/history','/monitoring/vwls/realtime']
            let route = this.$route;
            if(showSiteSelectRoutes.indexOf(route.path)>=0){                
                return true
            }
            return false;
        },
        showSiteTypeSelect(){
            let route = this.$route;
            if(route.path.indexOf('/monitoring') >= 0){                
                return true
            }
            return false;
        },
        logoPath(){            
            if(this.$store.state.storage_user?.user?.company?.logo){                            
                return this.$store.state.storage_user.user.company.logo;
            }       
            return require("@/assets/img/logo/guoyao.png")     
        },       
        avatorUrl(){
            if(this.$store.state.storage_user?.user?.extendInfo?.avator){
                return uploadMinioURL + this.$store.state.storage_user?.user?.extendInfo?.avator;
            }            
            return uploadMinioURL + 'web/image/avator/a1.png'
        },   
        colors(){            
            return colors;
        },  
        showSub(){
            return (item)=>{                   
                return item.children.find(a=>{return a.activeName == this.activeName})?.activeName
            }
        },
        siteTypes(){            
            return this.$store.state.storage_user.siteTypes.filter(a=>{
                let sites = this.$store.state.storage_user.sites.filter(b=>{                                        
                    return b.type == a.code
                })
                if(sites.length > 0){
                    //权限判断
                    var permission =JSON.parse(this.$store.state.storage_user?.role?.permission) 
                    if(permission){                                
                        var clientMenu = permission?.menu?.client                                               
                        if((a.code == '0' && clientMenu.find(b=>b.key == 'monitoring.pls')?.show)||
                        (a.code == '1' && clientMenu.find(b=>b.key == 'monitoring.pmls')?.show)||
                        (a.code == '2' && clientMenu.find(b=>b.key == 'monitoring.vls')?.show)||
                        (a.code == '3' && clientMenu.find(b=>b.key == 'monitoring.agt')?.show)||
                        (a.code == '4' && clientMenu.find(b=>b.key == 'monitoring.wls')?.show)||
                        (a.code == '5' && clientMenu.find(b=>b.key == 'monitoring.vwls')?.show)){                           
                            return a
                        }
                    }                    
                }
            });
        },
        sites(){     
            if(!this.currentType || typeof(this.currentType.code) == "undefined" || this.currentType.code == null ){                
                return []                                       
            }
            var sites = this.$store.state.storage_user.sites.filter(a=>{                               
                return a.type == this.currentType.code;
            })     
            sites = sites.sort((a,b)=>{
                return b.commitStatus - a.commitStatus;
            })       
            if(!this.currentSite.siteId || this.currentSite.type != this.currentType.code){
                if(sites.length > 0){                
                    this.currentSite = sites[0]   
                    this.$store.commit("storage_global/updateCurrentSite",this.currentSite,{root:true})             
                }else{
                    this.currentSite ={
                        siteName:'暂无可用站点'
                    }
                }
            }
            var that = this;
            return sites.filter(a=>{
                if(!that.search){
                    return true;
                }
                return a.siteName.indexOf(that.search) >= 0
            });
        },
        menus:function(){
            return menuJson.client.map(a=>{
                if(a.children){ 
                    let title = this.$i18n.t(`layout.menu.title.${a.key}`); 
                    if(a.key == "monitoring.pls" && this.$store.state.storage_user?.user?.extendInfo?.alias?.route?.pls){
                        title = this.$store.state.storage_user?.user?.extendInfo?.alias?.route?.pls
                    }  
                    if(a.key == "monitoring.vls" && this.$store.state.storage_user?.user?.extendInfo?.alias?.route?.vls){
                        title = this.$store.state.storage_user?.user?.extendInfo?.alias?.route?.vls
                    }               
                    return {
                        icon:a.icon,
                        path:a.path,
                        name:a.name,
                        activeName:a.activeName,
                        show:this.getUserMenu(a.key),
                        title:title,
                        children:a.children.map(b=>{
                            return {
                                icon:b.icon,
                                path:b.path,
                                name:b.name,
                                activeName:b.activeName,
                                show:this.getUserMenu(b.key),
                                title:this.$i18n.t(`layout.menu.title.${b.key}`),
                            }
                        })
                    }
                }                
                 return {
                     icon:a.icon,
                     path:a.path,
                     name:a.name,
                     activeName:a.activeName,
                     show:this.getUserMenu(a.key),
                     title:this.$i18n.t(`layout.menu.title.${a.key}`)
                 }
            })          
        },
        notLink(){
            console.log(localStorage.getItem("checkLink"))
            return !localStorage.getItem("checkLink") 
        } 
    },
    methods:{    
        getQr(){
            var that = this;            
            if(!that.$refs.qrcode|| that.$refs.qrcode.children.length == 0){
                setTimeout(() => {
                    new QRCode(that.$refs.qrcode, {
                        text: sysconfig.promotionalDocuments,
                        width: 100,
                        height: 100,
                        colorDark : '#000000',
                        colorLight : '#ffffff',
                        correctLevel : QRCode.CorrectLevel.H
                    });
                }, 500);                 
            }
        },  
        playVidio(){            
            this.videoDialog = true;      
        },
        minCssConfig(setcss){
            if(setcss){                    
                document.getElementsByTagName('html')[0].style.minWidth='1200px'
                document.body.style.minWidth='1200px'
                document.getElementsByClassName('v-application')[0].minWidth='1200px'  
                document.getElementsByTagName('html')[0].style.minHeight='800px'
                document.body.style.minHeight='800px'
                document.getElementsByClassName('v-application')[0].minHeight='800px'
            }else{
                document.getElementsByTagName('html')[0].style.minWidth=''
                document.body.style.minWidth=''
                document.getElementsByClassName('v-application')[0].minWidth=''  
                document.getElementsByTagName('html')[0].style.minHeight=''
                document.body.style.minHeight=''
                document.getElementsByClassName('v-application')[0].minHeight=''
            }
        },
        //监听消息
        watchingNotice(){
            //监听所有启用站点
            var sites = this.$store.state.storage_user.sites.filter(a=>{
                return a.type != '3' && a.transStatus == 0// 不是走航车并且启用
            })
            var that = this;            
            getSiteExchangeStringAsync({
                params:{
                    siteIds:sites.map(a=>{
                        return a.siteId
                    })
                },
                success:(d)=>{
                    that.alarmSubscribe(d);                                     
                }
            })
        },     
        alarmSubscribe(subtitles){                       
            let host = sysconfig.wsUrl;  
            if(this.webSocketClient == null){
                this.webSocketClient = Stomp.client(host);  
                this.webSocketClient.debug = null                                              
            }
            var userid = this.$store.state.storage_user.user.id;
            var headers = {
                login:sysconfig.wsacc,
                passcode:sysconfig.wspc,
                'client-id': 'dbcenter.'+userid,            
            }               
            var that = this;
            //订阅报警
            var subscribe = function(){   
                for(var subtitle of subtitles){             
                    that.webSocketClient.subscribe(`/exchange/${subtitle}/dbcenter.${userid}`,function(d){                         
                        if(that.reconnectTime > 0){
                            that.reconnectTime = 0
                        }                     
                        //订阅成功                            
                        var data = JSON.parse(d.body)                           
                        if(data.IsGZip){                            
                            data.Json = $unzip(data.Json,data.SiteId)
                        }                            
                        data = transferHelper(data)          
                        if(!data){
                            return;
                        }
                        var _site = that.$store.state.storage_user.sites.find(a=>{                               
                            return a.siteId == data.SiteId;
                        })                                                                                                            
                        if(_site.type == 2 && data.DataType == 2){
                            return
                        }                        
                        //延时处理 超时丢弃
                        let _current = that.dataUpdateInfo.find(a=>a.siteId == data.SiteId && a.dataType == data.DataType);
                        let upt = data.DataTime ? new Date(data.DataTime.replace('T'," ")).getTime():new Date().getTime()
                        if(!_current){
                            _current ={
                                siteId:data.SiteId,
                                dataType:data.DataType,
                                updateTime:upt
                            }
                            that.dataUpdateInfo.push(_current)
                        }
                        if(_current.updateTime > upt){
                            return;
                        }
                        if(!data?.jsonData|| data.DataType == 6  ||data?.jsonData.length==0){
                            return;
                        }                                                                                            
                        if(_site && data.ScanMode &&_site.scanType != data.ScanMode){    
                            that.$store.commit("storage_user/updateSiteScanType",{
                                siteId:data.SiteId,
                                scanType:data.ScanMode,                                    
                            },{root:true})                                
                        }              
                        if(_site && data.ScanMode && _site.siteId == that.currentSite.siteId && _site.scanType != that.currentSite.scanType){
                            that.$store.commit("storage_global/updateCurrentSiteScanType",{
                                siteId:data.SiteId,
                                scanType:data.ScanMode,                                    
                            },{root:true})                                                             
                        }                                                         
                        that.$store.commit("storage_global/updateSiteData",{data:data},{root:true})                                                                              
                    },{                        
                        'durable':false,
                        'auto-delete':true,                    
                    })
                   
                }  
                //测试 
                // that.webSocketClient.subscribe(`/exchange/WCTest/dbcenter.${userid}`,function(d){                       
                //     var unz = $unzip(d.body)
                //     console.log(unz)

                // })              
            }                         
            var subAlarmPoint = function(){
                 //订阅报警                 
                that.$store.state.storage_user.sites.forEach(a=>{
                    if(a.type != '3' && a.transStatus == 0)// 不是走航车并且启用
                    {
                        //特殊报警
                        if(a.siteId == 'DALSHAN_370102_07_02'){
                            setInterval(() => {
                                let nowTime = new Date();
                                if(nowTime.getMinutes == 0){
                                    let end = nowTime.pattern('yyyy-MM-dd HH:mm:ss')
                                    let start = new Date(nowTime.setTime(nowTime.getTime() - 3600 * 1000)).pattern('yyyy-MM-dd HH:mm:ss')
                                    getAlarmPointComputedsApi({
                                            id:'DALSHAN_370102_07_02',
                                            params:{
                                                PageIndex:1,
                                                PageSize:100,
                                                StartTime:start,
                                                EndTime:end,                 
                                            }, success:(d)=>{            
                                                let computedData = d.ComputedData.map(a=>{
                                                    return {
                                                        lidarSN:'DALSHAN_370102_07_02',
                                                        lng:a.lng,
                                                        lat:a.lat,
                                                        level:0,
                                                        count:$help.sum(a.intensity),
                                                        date:end,
                                                        dataType:6
                                                    }
                                                }) 
                                                that.addToMessage(computedData.slice(0,20))                                                                                             
                                            } 
                                    })
                                }
                            }, 1000 * 60);
                        }else{
                            that.webSocketClient.subscribe(`/exchange/${sysconfig.alarmPointTopic}/#.${a.siteId}.#`,function(d){                       
                                //订阅成功                                
                                if(that.reconnectTime > 0){
                                    that.reconnectTime = 0
                                }                                                                               
                                var data = JSON.parse(d.body)                                                                
                                if(data.DataType == 54){
                                    that.$store.commit('storage_global/updateAlarmProfile',{
                                        siteId:data.SiteId,
                                        data:JSON.parse(data.Json),
                                        dataType:data.DataType
                                    })
                                }
                                if(data.DataType == 6){                                                                        
                                    let alarmPoints = JSON.parse(data.Json).map(b=>{
                                        let pointFilter = b.DapRule ? JSON.parse(b.DapRule) : []                                        
                                        return {
                                            lidarSN:b.DapSiteId,
                                            lng:b.DapLng,
                                            lat:b.DapLat,
                                            level:b.DapLevel,
                                            count:b.DapCount,
                                            date:b.DapTime,
                                            dataType:b.DapTypeId,
                                            pointFilter:pointFilter
                                        }
                                    }).filter(b=>{
                                        let rel = $help.alarmDistanceFilter(a.lng,a.lat,b.lng,b.lat,a.scanDistance)
                                        return rel
                                    })
                                    if(alarmPoints.length > 0){
                                        that.addToMessage(alarmPoints.slice(0,20))
                                    }                                    
                                } 
                            },{                        
                                'durable':false,
                                'auto-delete':true,                    
                            })
                        }                       
                    }
                })               
               
            }    
            //订阅消息提醒
            var subMessage = function(){
                that.webSocketClient.subscribe(`/exchange/${sysconfig.TimingMessageTopic}/#.${userid}.#`,function(d){                       
                    if(that.reconnectTime > 0){
                        that.reconnectTime = 0
                    }                     
                    if(d.body){
                        //var item = JSON.parse(d.body)
                        that.snackbar = {
                            show:true,
                            text:"您有一条新通知，请前往消息中心查看"
                        }
                        that.message.push("您有一条新通知")                        
                    }                    
                },{                      
                    'id':userid,
                    'durable':false,
                    'auto-delete':true,                    
                })
                that.webSocketClient.subscribe(`/exchange/${sysconfig.TimingMessageTopic}/#.all.#`,function(d){    
                    if(that.reconnectTime > 0){
                        that.reconnectTime = 0
                    }                                        
                        //订阅成功                    
                    if(d.body){
                        var item = JSON.parse(d.body)
                        that.snackbar = {
                            show:true,
                            text:"您有一条新消息，请前往消息中心查看"
                        }
                        that.message.push("您有一条新通知")                      
                    }           

                },{                                          
                    'durable':false,
                    'auto-delete':true,                    
                })
            }

            //订阅外场设备
             var subExternalDevice = function(){
                for(let i of that.$store.state.storage_user.devices){
                    let id = i.id;                    
                    that.webSocketClient.subscribe(`/exchange/${sysconfig.externalDeviceDataTopic}/#.${i.code}.#`,function(d){                       
                        if(that.reconnectTime > 0){
                            that.reconnectTime = 0
                        }                     
                        //订阅成功                              
                        if(d.body){                           
                            var datas = JSON.parse(JSON.parse(d.body).Json).filter(c=>{
                                let diff = new Date(c.EdmTime.replace("T"," ")).getTime() - new Date().getTime();
                                if( 0< diff && diff< 10 * 1000 * 60){
                                    return c;
                                } 
                            }).map(c=>c.EdmValue)                            
                            //console.log(datas,JSON.parse(JSON.parse(d.body).Json))    
                            that.$store.commit('storage_user/updateDeviceData',{
                               id:id,
                               value:datas
                            },{root:true})
                        }                    
                    },{                                          
                        'durable':false,
                        'auto-delete':true,                    
                    })              
                }               
            }
              
            if(this.webSocketClient == null || !this.webSocketClient.connected){                     
                this.webSocketClient.connect(headers, function(f){                                    
                    //链接成功回调
                    //订阅                                                                           
                    subscribe()
                    subMessage();
                    subExternalDevice()
                    subAlarmPoint()
                }, function(f){
                    //失败回调            
                    console.log('err',f)
                });       
                this.webSocketClient.heartbeat.incoming = 0;      
                                  
                this.webSocketClient.ws.onclose = (e)=>{  
                    console.log("链接断开",e)                    
                    if(that.reconnectTime < 30){
                        that.reconnectTime++;
                        that.webSocketClient.disconnect();
                        that.webSocketClient = null;
                        that.alarmSubscribe(subtitles)                    
                    }
                }
            }else{                               
                subscribe()
            }
            
        },  
        addToMessage(alarmPoints){
            var that = this;            
            if(this.messageGisTimmer){
                clearTimeout(this.messageGisTimmer)
            }
            let site = this.$store.state.storage_user.sites.find(a=>{
                return a.siteId == alarmPoints[0].lidarSN
            })   
            if(!site){
                return;
            }            
            this.messageGisTimmer = setTimeout(() => {                                 
                 if(alarmPoints.length > 0){
                        let lnglatstr= alarmPoints.map(a=>{
                            return `${a.lng},${a.lat}`
                        }).join('|') 
                        getGisApi({
                            params:{
                                location:lnglatstr
                            },
                            success:(f)=>{
                                let messageList = [];
                                alarmPoints.forEach((a,i)=>{                                       
                                    let gisInfo = f.find(b=>{
                                        var lnglat = b.GrLngLat.split(',');                                        
                                        return  Number(lnglat[0]).toFixed(4) == a.lng.toFixed(4) && Number(lnglat[1]).toFixed(4) == a.lat.toFixed(4)
                                    }) 
                                    if(gisInfo){  
                                        var position = JSON.parse(gisInfo.GrLoc)                             
                                        let message = {
                                            siteId:a.lidarSN,                                                
                                            date:new Date(a.date.replace("T"," ")).pattern("yyyy-MM-dd HH:mm:ss"),
                                            level:a.level,
                                            count:a.count,    
                                            lng:a.lng,
                                            lat:a.lat,
                                            dataType:a.dataType,
                                            position:position,
                                            pointFilter:a.pointFilter                           
                                        }
                                        setTimeout(() => {
                                            that.$store.commit('storage_global/updateMessage',message,{root:true})                                                
                                        }, 100*i);                                            
                                        messageList.push(message);
                                    }
                                        
                                })
                                that.message = messageList.concat(that.message)
                            }
                        }) 
                    }          
            }, 200);
                
        },
        //控制主题显示隐藏
        setThemeConfigShow(val){
            this.themeConfigShow = val;
        },
        //导航
        goto(item,key){            
           this.$router.push({name:item.name})
        },   
        setThemeColor(color){            
            let theme = themColorHelper(color)
            this.$vuetify.theme.themes = theme
            //重置css变量
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark 
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark 
            //添加缓存
            let currentTheme = JSON.stringify({color:color,dark:this.$vuetify.theme.dark})
            localStorage.setItem("currentTheme",currentTheme)
        },   
        setDark(val){                                
            this.$vuetify.theme.dark = val;
            let currentTheme = JSON.stringify({color:this.themeColor,dark:this.$vuetify.theme.dark})
            localStorage.setItem("currentTheme",currentTheme)
        },
        getUserMenu(key){            
            //禁用菜单
            let noShow = ["about"]
            if(noShow.indexOf(key)>=0){
                return false;
            }          
            if(!this.$store.state.storage_user?.role?.permission){
                return false
            }
            //权限设置   
            var permission =JSON.parse(this.$store.state.storage_user?.role?.permission)       
            if(permission){                                
                var adminMenu = permission?.menu?.client                   
                if(adminMenu){
                    var isShow = this.getShow(adminMenu,key);                                    
                    if(key.indexOf("pls") >=0){
                        let sites = this.$store.state.storage_user.sites.filter(a=>{
                            //1550 能见度 偏振比
                            return a.type =='0';
                        })                      
                          
                        if(sites.length == 0){
                            isShow =  false
                        }
                    }
                    if(key.indexOf("pmls") >=0){
                        let sites = this.$store.state.storage_user.sites.filter(a=>{
                            //1550 能见度 偏振比
                            return a.type =='1';
                        })                      
                          
                        if(sites.length == 0){
                            isShow =  false
                        }
                    }
                    if(key.indexOf("vls") >=0){
                        let sites = this.$store.state.storage_user.sites.filter(a=>{
                            //1550 能见度 偏振比
                            return a.type =='2';
                        })                      
                          
                        if(sites.length == 0){
                            isShow =  false
                        }
                    }
                    if(key.indexOf("agt") >=0){                        
                        let sites = this.$store.state.storage_user.sites.filter(a=>{
                            //走航车
                            return (a.type == '3');
                        })
                        if(sites.length == 0){
                            isShow = false
                        }
                    }        
                    if(key.indexOf("wls") >=0){                        
                        let sites = this.$store.state.storage_user.sites.filter(a=>{
                            //测风
                            return (a.type == '4');
                        })
                        if(sites.length == 0){
                            isShow = false
                        }
                    }       
                    if(key.indexOf("vwls") >=0){                        
                        let sites = this.$store.state.storage_user.sites.filter(a=>{
                            //测风
                            return (a.type == '5');
                        })
                        if(sites.length == 0){
                            isShow = false
                        }
                    }                      
                    return isShow;
                }
            }                        
            return false;            
        },
        getShow(items,key,isShow = false){     
            items.forEach(a=>{
                if(a.key == key && a.show){
                    isShow = true
                    return isShow;
                }else{                   
                    if(a.children){
                        isShow = this.getShow(a.children,key,isShow)
                    }
                    return isShow;
                }
            })
            return isShow;
        },
        logout(){            
            var that = this;
            //请求   
            loginOutApi({
                success:(d)=>{
                    if(d){
                        localStorage.removeItem("token")
                        if(that.webSocketClient){
                            that.webSocketClient.disconnect()
                            that.webSocketClient = null;
                        }  
                        that.minCssConfig()
                        if($help.isMobile()) {            
                            that.$router.push({name:"m.login"})            
                        }else{
                            that.$router.push({
                                path:'/login',               
                            })   
                        }    
                              
                                             
                    }                       
                }
            })                
        },
        //设置主题按钮位置
        setPosition(){
            var that = this;
            let fun = (e)=>{
                that.configIconPosition.offsetX += e.movementX;
                that.configIconPosition.offsetY += e.movementY;
                localStorage.setItem("configIconOffsetY",that.configIconPosition.offsetY)
            }
            document.addEventListener('mousemove',fun)
            document.addEventListener('mouseup', ()=>{
                document.removeEventListener('mousemove',fun)
            });
        }
    }    
}
</script>
<style scoped lang="scss">
.mainBody {
    width:100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.hideBtn {
    padding: 10px;
    height: 50px;
}
.main-view {
    height: 100%;
    width: 98%;
}

.theme-setting {
    overflow: inherit !important;  
    visibility: inherit !important; 
    
}
.iconConfig{
    width: 34px;
    height: 34px;
    position: absolute;
    padding: 5px;
    border-radius: 50%;
    background: var(--v-primary-base);
    cursor: pointer;
  //  color: var(--v-primary-base);
}
.themeConfigBody{
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 10px 20px;   
}
.themeColors{
    overflow: auto;    
    margin-top: 10px;
}
.colorChip{
    border-radius: 0 !important;
    width: 30px;
    border: 4px solid transparent !important;
    height: 24px !important;
    margin: 4px !important;
}
.colorChip:hover{
    animation: scalePreset 0.5s;
    -webkit-animation: scalePreset 0.5s;
    animation-fill-mode:forwards;
    -webkit-animation-fill-mode:forwards;
}
@keyframes scalePreset {
    100%{
        transform:scale(1.2)
    }
}
.colorChipActive{    
    flex-shrink: 0;
    border: 4px solid var(--v-secondary-base) !important;
}
.theme-setting {
   :deep(.v-navigation-drawer__content){
       overflow: inherit !important;  
    } 
}
.sliderNav:hover{
    background: var(--v-primary-base);
    color:var(--v-white-base) !important;
    .v-icon{
        color:var(--v-white-base) !important;
    }
}
.sliderNavGroup{
    :deep(.v-list-group__header){
        //padding:4px;
        margin-top: 4px;
    }
    :deep(.v-list-group__header:hover){
        background: var(--v-primary-base);
        color:var(--v-white-base) !important;
        .v-icon{
            color:var(--v-white-base) !important;
        }
    }    
}
:deep(.groupActive){
        background: var(--v-primary-base);
        color:var(--v-white-base) !important;
        .v-icon{
            color:var(--v-white-base) !important;
        }
    }


.v-main{
    height: 100%;
    display: flex;
    flex: auto;
}
:deep(.v-main__wrap){
    display: flex;
    max-height: 100%;
    align-items: center;
}
.view-box{
    height: 100%;
    width: 100%;
}
.qrbox{
    position: absolute;      
    bottom: 35px;
    width: 100%;
}
.qrcode{   
    padding: 5px;
    background: url('~@/assets/img/loginbox.png');            
    background-size: 100% 100%; 
}
.topSheet{
    position: fixed;
    width: 100%;
    z-index:1;
}
.siteSelect{
    :deep(span){
        width: 100%;
        justify-content: left !important;
    }
}
</style>



